/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { useContext, useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { FirebaseContext } from '../Firebase/context';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import { MyUser } from '../Firebase/firebase';
import firebase from 'firebase';
import { resources } from '../../resources';

const res = resources.responses;

const StyledTableCell = withStyles((theme) => ({
  head: {
    // @ts-ignore
    backgroundColor: theme.palette.primary.A400,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      // @ts-ignore
      backgroundColor: theme.palette.primary.A100,
    },
  },
}))(TableRow);

interface RsvpResonse {
  names: string;
  can: boolean;
  later: boolean;
  comment: string;
  timestamp: firebase.firestore.Timestamp;
}

const RsvpResponsesBase = () => {
  const firebase = useContext(FirebaseContext);
  const [responses, setResponses] = useState<{ [key: string]: RsvpResonse }>(
    {}
  );

  useEffect(() => {
    console.log('Loading rsvp responses from backend');
    firebase?.getRsvpResponses().then((querySnapshot) => {
      const results = {};
      // @ts-ignore
      querySnapshot.forEach((doc) => (results[doc.id] = doc.data()));
      setResponses(results);
      console.log('Loading rsvp responses from backend finished');
    });
  }, [firebase]);

  return (
    <div>
      <Typography variant="h4">{res.title}</Typography>
      <br />
      <div>
        {' '}
        {res.textResponsesSoFar(Object.keys(responses).length)}
        <br />
        {res.textAcceptedSoFar(
          Object.values(responses).filter((value) => value.can).length
        )}
        <br />
        {res.textDeclinedSoFar(
          Object.values(responses).filter((value) => !value.can).length
        )}
        <br />
        {res.textLaterSoFar(
          Object.values(responses).filter((value) => value.later).length
        )}
      </div>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{res.labelNames}</StyledTableCell>
              <StyledTableCell>{res.labelComing}</StyledTableCell>
              <StyledTableCell>{res.labelComment}</StyledTableCell>
              <StyledTableCell>{res.labelLater}</StyledTableCell>
              <StyledTableCell>{res.labelRegistrationTime}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(responses).map(([responseId, response]) => (
              <StyledTableRow key={responseId}>
                <StyledTableCell>{response.names}</StyledTableCell>
                <StyledTableCell>
                  {response.can ? 'ja' : 'Nein'}
                </StyledTableCell>
                <StyledTableCell>{response.comment}</StyledTableCell>
                <StyledTableCell>
                  {response.later ? 'ja' : 'Nein'}
                </StyledTableCell>
                <StyledTableCell>
                  {response.timestamp.toDate().toLocaleDateString('de-DE')}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const condition = (authUser: MyUser | null) =>
  !!authUser && ROLES.ADMIN in authUser.role;
export const RsvpResponses = withAuthorization(condition)(RsvpResponsesBase);
