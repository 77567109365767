import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles({
  iframe: {
    border: 0,
    width: 300,
    height: 300,
    frameBorder: 0,
    display: 'inline-block',
  },
  card: {
    textAlign: 'start',
    width: 308,
    padding: 4,
    margin: 'auto',
  },
});

interface Location {
  link: string;
  name: string;
  city: string;
  street: string;
  description: string;
}

export const LocationItem = ({
  link,
  name,
  city,
  street,
  description,
}: Location) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} raised={true}>
      <address>
        <b>
          {name}, <br />
          {description}{' '}
        </b>
        <br />
        {city}, {street}
        <br />
        <br />
      </address>
      <iframe
        title={'test'}
        src={link}
        className={classes.iframe}
        allowFullScreen
      />
    </Card>
  );
};
