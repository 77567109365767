import React, { ElementType } from 'react';
import { AuthUserContext } from './index';
import { Firebase, withFirebase } from '../Firebase';
import { MyUser } from '../Firebase/firebase';

interface AutUserState {
  authUser: MyUser | null;
}
// Stores merged (firebase db + auth) user in state and distributes it to other components via react context
// provider component
export const withAuthentication = (Component: ElementType) => {
  class WithAuthentication extends React.Component<{}, AutUserState> {
    private listener: () => void;
    private firebase: Firebase;

    constructor(props: { firebase: Firebase }) {
      super(props);
      this.firebase = props.firebase;
      const cachedUser = localStorage.getItem('authUser');
      this.state = {
        authUser: cachedUser ? JSON.parse(cachedUser) : null,
      };
    }

    componentDidMount() {
      // Whenever the authenticated user changes update the state
      this.listener = this.firebase.onAuthUserListener(
        (authUser) => {
          // Use of local storage as cache for better performance
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });
        },
        () => {
          localStorage.removeItem('authUser');
          this.setState({ authUser: null });
        }
      );
    }

    componentWillUnmount() {
      // On unmount remove listener to avoid memory leaks.
      this.listener();
    }

    //wrap returned component in context to provide it with access to the authUser context/prop
    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};
