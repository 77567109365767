import React from 'react';
import { LocationItem } from './LocationItem';
import Grid from '@material-ui/core/Grid';
import { Separator } from '../BasicComponents';
import ivyHeader from '../../images/ivyHeader.png';
import { Typography } from '@material-ui/core';

export const anchor = 'location';
export const Location = () => {
  const locationItems = [
    {
      name: 'Schlosskapelle Ottenburg',
      city: '85386 Eching',
      street: 'Weinbergstraße 26',
      description: 'Standesamtliche Trauung',
      link:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10613.16286512756!2d11.600512641337065!3d48.316491418371214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e6fedf2defe8b%3A0x8f1ae60406aea472!2sSt.%20Georg%20Kapelle!5e0!3m2!1sen!2sde!4v1576248546161!5m2!1sen!2sde',
    },
    {
      name: 'Apollo XIV',
      city: '85386 Eching',
      street: 'Wielandstraße 14',
      description: 'Hochzeitsfeier',
      link:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1115.9666099715196!2d11.62430273715671!3d48.29841111424357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdba485ff145198e8!2sApollo%20XIV!5e0!3m2!1sen!2sde!4v1576246881807!5m2!1sen!2sde',
    },
    {
      name: 'Hotel Olymp',
      city: '85386 Eching',
      street: 'Wielandstraße 3',
      description: 'Empfohlenes Hotel',
      link:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1876.8187963732823!2d11.624750118655342!3d48.298570168218234!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd22762549e3448d4!2sGOLDEN%20TULIP%20OLYMP!5e0!3m2!1sen!2sde!4v1576248456035!5m2!1sen!2sde',
    },
  ];

  return (
    <div id={anchor}>
      <Separator src={ivyHeader} />
      <Typography variant="h4">Location:</Typography>
      <p>Hier findet unsere Hochzeit statt:</p>
      <Grid container spacing={1}>
        {locationItems.map((item, index) => {
          return (
            <Grid item xs key={index}>
              <LocationItem {...item} />
            </Grid>
          );
        })}
      </Grid>
      <br />
      <p>
        Wenn ihr mit dem Auto kommt, könnt ihr die Tiefgarage des Hotels nutzen.
        Von der S-Bahn-Station Eching aus ist das Hotel gut zu Fuß erreichbar.
      </p>
      <p>
        Wir haben für euch einige Zimmer im
        <a
          href="https://www.olympmunich.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Hotel Olymp
        </a>{' '}
        reserviert. Meldet euch dafür einfach bis spätestens 24. April 2020
        direkt beim Hotel und gebt an, dass ihr zur Hochzeit Erkenberg gehört.
        Ein Doppelzimmer kostet 110 Euro für eine Übernachtung.
      </p>
      <p>Weitere Übernachtungsmöglichkeiten in Eching:</p>
      <ul>
        <li>
          <a
            href="http://brauerei-echinger-hof.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pension Echinger Hof
          </a>
        </li>
        <li>
          <a
            href="https://www.huberwirt.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hotel Huberwirt{' '}
          </a>
        </li>
      </ul>
    </div>
  );
};
