export const resources = {
  countdown: {
    labelDays: 'Tage',
    labelHours: 'Stunden',
    labelMinutes: 'Minuten',
    labelSeconds: 'Sekunden',
  },
  dialog: {
    labelAgree: 'Ja',
    labelCancel: 'Abbrechen',
    labelDeleteItem: 'Diesen Eintrag wirklich löschen?',
  },
  faq: {
    title: 'Hochzeits FAQ',
    textIntro:
      'Fragen zur Hochzeit? Hier findet ihr die Antworten! Und falls doch nicht schreibt uns ' +
      'eine E-mail an <hochzeit@erkenberg.info>.',
  },
  imageUpload: {
    labelUploadImage: 'Bilder hinzufügen:',
    labelPreview: 'Vorschau',
    labelDropZone: 'Bilder hierher ziehen oder klicken',
    labelCancel: 'Abbrechen',
    labelSubmit: 'Hochladen',
    labelUploadName: 'Name',
    labelSelectImages: 'Bilder auswählen',
    textUploadImage:
      'Wenn ihr eigene Bilder gemacht habt, würden wir uns sehr freuen, wenn wir sie bekommen können :) ' +
      'Gebt einfach euren Namen ein und wählt die Bilder aus, die ihr gern mit uns teilen wollt.\n\n' +
      'Maximal gehen 20 Bilder auf einmal, ihr könnt aber mehrfach hochladen, falls ihr mehr habt. ' +
      'Die Bilder sind nur für uns sichtbar und tauchen nicht in der Gallerie auf.',
    textUploadSuccessful: 'Hochladen erfolgreich!',
  },
  genericListItems: {
    labelEditTitle: 'Titel',
    labelEditText: 'Inhalt',
    labelPreview: 'Vorschau',
    labelAddItem: 'Neuen Eintrag anlegen',
    labelEditItem: 'Eintrag bearbeiten',
    labelDeleteItem: 'Diesen Eintrag wirklich löschen?',
    textWarnDelete: 'Das Löschen kann nicht rückgängig gemacht werden!',
  },
  homePage: {
    title: 'Patricia und Stefan sind verheiratet!',
    countDownDate: '05 22 2020, 9:00 am',
    textSubtitle: 'Und ihr seid eingeladen!',
  },
  login: {
    invalidPassword: 'Falsches Passwort',
    labelPassword: 'Passwort',
  },
  navigation: {
    labelInfo: 'Infos',
    labelResponses: 'Rückmeldungen',
    labelGallery: 'Galerie',
    labelLogOut: 'Ausloggen',
    labelRegistration: 'Anmeldung',
    labelProgram: 'Programm',
    labelNews: 'Neuigkeiten',
    labelFaq: 'FAQ',
    labelLocation: 'Location',
  },
  news: {
    title: 'Neuigkeiten',
  },
  responses: {
    title: 'Rückmeldungen',
    textResponsesSoFar: (num: number) => `Anzahl Rückmeldungen bisher: ${num}`,
    textAcceptedSoFar: (num: number) => `Anzahl Zusagen: ${num}`,
    textDeclinedSoFar: (num: number) => `Anzahl Absagen: ${num}`,
    textLaterSoFar: (num: number) => `Anzahl Nachzügler: ${num}`,
    labelNames: 'Namen',
    labelComing: 'Kommt',
    labelComment: 'Kommentar',
    labelLater: 'Später',
    labelRegistrationTime: 'Anmeldezeit',
  },
  program: {
    title: 'Programm',
    labelDate: 'Freitag, 22. Mai 2020',
  },
};
