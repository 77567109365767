import React from 'react';
import { Separator, EditableList } from '../BasicComponents';
import ivyHeader from '../../images/ivyHeader.png';

import { documentIDs } from '../Firebase/firebase';
import Typography from '@material-ui/core/Typography';

import { resources } from '../../resources';

const res = resources.news;

export const anchor = 'news';
export const News = () => {
  return (
    <div id={anchor}>
      <Separator src={ivyHeader} />
      <Typography variant="h4">{res.title}:</Typography>
      <EditableList
        id="News"
        firebasePath={documentIDs.news}
        showDates
        sortDateDescending={true}
      />
    </div>
  );
};
