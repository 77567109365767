import React, { ReactText, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { SVGCircle } from './SVGCircle';
import { resources } from '../../resources';

const res = resources.countdown;

//based on https://www.florin-pop.com/blog/2019/05/countdown-built-with-react/
// adapted for hooks and material UI styling
const useStyles = makeStyles({
  root: {
    boxSizing: 'borderBox',
  },
  countdownWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  countdownItem: {
    color: '#8c5221',
    fontSize: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    lineHeight: '30px',
    margin: '10px',
    paddingTop: '10px',
    position: 'relative',
    width: '100px',
    height: '100px',
    '& span': {
      color: '#8c5221',
      fontSize: '12px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  },
} as any);

interface TimeUntil {
  days: number;
  hours: ReactText | number;
  minutes: ReactText | number;
  seconds: ReactText | number;
}

const mapNumber = (
  number: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) => {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export const Countdown = ({
  timeTillDate,
  timeFormat,
}: {
  timeTillDate: string;
  timeFormat: string;
}) => {
  const classes = useStyles();
  const [timeUntil, setTimeUntil] = useState({} as TimeUntil);

  useEffect(() => {
    const update = () => {
      const target: Moment = moment(timeTillDate, timeFormat);
      const now: Moment = moment();
      const countdown = moment(target.diff(now));
      const days = target.diff(now, 'days');
      setTimeUntil({
        days: days < 0 ? 0 : days,
        hours: days < 0 ? 0 : countdown.format('HH'),
        minutes: days < 0 ? 0 : countdown.format('mm'),
        seconds: days < 0 ? 0 : countdown.format('ss'),
      });
    };
    update();
    const interval = setInterval(() => {
      update();
    }, 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timeTillDate, timeFormat]);
  if (!timeUntil || Object.keys(timeUntil).length !== 4)
    return <div style={{ height: '120px' }} />;

  const daysRadius =
    timeUntil.days <= 0 ? 359.99 : mapNumber(timeUntil.days, 365, 0, 0, 360);
  const hoursRadius =
    timeUntil.hours <= 0
      ? 359.99
      : mapNumber(timeUntil.hours as number, 24, 0, 0, 360);
  const minutesRadius =
    timeUntil.minutes <= 0
      ? 359.99
      : mapNumber(timeUntil.minutes as number, 60, 0, 0, 360);
  const secondsRadius =
    timeUntil.seconds <= 0
      ? 359.99
      : mapNumber(timeUntil.seconds as number, 60, 0, 0, 360);

  return (
    <div>
      <div className={classes.countdownWrapper}>
        <div className={classes.countdownItem}>
          <SVGCircle radius={daysRadius} />
          {timeUntil.days}
          <span>{res.labelDays}</span>
        </div>
        <div className={classes.countdownItem}>
          <SVGCircle radius={hoursRadius} />
          {timeUntil.hours}
          <span>{res.labelHours}</span>
        </div>
        <div className={classes.countdownItem}>
          <SVGCircle radius={minutesRadius} />
          {timeUntil.minutes}
          <span>{res.labelMinutes}</span>
        </div>
        <div className={classes.countdownItem}>
          <SVGCircle radius={secondsRadius} />
          {timeUntil.seconds}
          <span>{res.labelSeconds}</span>
        </div>
      </div>
    </div>
  );
};
