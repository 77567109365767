import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  countdownSvg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100px',
    height: '100px',
  },
});

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
  return [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');
};

export const SVGCircle = ({ radius }: { radius: number }) => {
  const classes = useStyles();
  return (
    <svg className={classes.countdownSvg}>
      <path
        fill="none"
        stroke="#bcaaa4"
        strokeWidth="4"
        d={describeArc(50, 50, 48, 0, radius)}
      />
    </svg>
  );
};
