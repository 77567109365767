import React, { ReactElement, useContext, useState } from 'react';
import { Button, makeStyles, Snackbar, TextField } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { resources } from '../../resources';
import { FirebaseContext } from '../Firebase';
import ReactMarkdown from 'react-markdown';
import MuiAlert from '@material-ui/lab/Alert';

const res = resources.imageUpload;

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1),
    width: '150px',
  },
  button: {
    margin: theme.spacing(1),
  },
  upload: {
    marginTop: theme.spacing(5),
  },
}));

export const ImageUpload = (): ReactElement => {
  const classes = useStyles();
  const [dropZoneOpen, setDropZoneOpen] = useState(false);
  const [uploadName, setUploadName] = useState('');
  const firebase = useContext(FirebaseContext);

  const onDialogSave = (files: File[]) => {
    const dirName = uploadName.replace(/\s/g, ''); // remove whitespaces
    if (dirName.length === 0) return;
    firebase?.uploadFiles(dirName, files);
    setDropZoneOpen(false);
    setUploadName('');
    setOpenSnackbar(true);
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const onDialogOpen = () => {
    setDropZoneOpen(true);
  };

  const onDialogClose = () => {
    setDropZoneOpen(false);
  };

  return (
    <>
      <div className={classes.upload}>
        <ReactMarkdown source={res.textUploadImage} />
        <TextField
          className={classes.textField}
          name={res.labelUploadName}
          value={uploadName}
          label={res.labelUploadName}
          type="text"
          variant="outlined"
          onChange={(e) => setUploadName(e.target.value)}
        />
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={onDialogOpen}
          startIcon={<CloudUploadIcon />}
          disabled={uploadName.trim().length === 0}
        >
          {res.labelSelectImages}
        </Button>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={() => setOpenSnackbar(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setOpenSnackbar(false)}
            severity="success"
          >
            {res.textUploadSuccessful}
          </MuiAlert>
        </Snackbar>
      </div>
      <DropzoneDialog
        open={dropZoneOpen}
        onSave={onDialogSave}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={onDialogClose}
        filesLimit={20}
        dropzoneText={res.labelDropZone}
        previewText={res.labelPreview}
        dialogTitle={res.labelUploadImage}
        cancelButtonText={res.labelCancel}
        submitButtonText={res.labelSubmit}
        showAlerts={['info']}
      />
    </>
  );
};
