import React from 'react';
import { Card, Grid, makeStyles } from '@material-ui/core';
import { LogInForm } from './LogInForm';
import ivyHeader from '../../images/ivyHeader.png';

const useStyles = makeStyles({
  card: {
    background: 'whiteBright',
  },
  grid: {
    minHeight: '100vh',
    direction: 'column',
    alignItems: 'center',
    justify: 'center',
  },
  imageItem: {
    maxWidth: '40%',
    minWidth: '350px',
    maxHeight: 'auto',
    '& img': {
      maxWidth: '100%',
      maxHeight: 'auto',
      margin: 'auto',
    },
  },
} as any);

export const LogIn = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={0} direction="column" className={classes.grid}>
      <Grid item className={classes.imageItem}>
        <img src={ivyHeader} className={classes.image} alt="" />
      </Grid>
      <Grid item>
        <Card className={classes.card} raised={true}>
          <LogInForm />
        </Card>
      </Grid>
    </Grid>
  );
};
