import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../Firebase/context';
import * as ROUTES from '../../constants/routes';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { Fingerprint } from '@material-ui/icons';
import { PasswordField } from '../BasicComponents';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { History } from 'history';
import { withRouter } from 'react-router-dom';
import { resources } from '../../resources';

const res = resources.login;

const useStyles = makeStyles({
  error: {
    color: 'red',
  },
});

const LogInFormBase = ({ history }: { history: History }) => {
  const classes = useStyles();
  const firebase = useContext(FirebaseContext);
  const emailGuest = 'hochzeit+gast@erkenberg.info';
  const emailAdmin = 'hochzeit+admin@erkenberg.info';
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const listener = firebase?.isUserLoggedInListener(() =>
      history.push(ROUTES.HOME)
    );
    return listener ? () => listener() : undefined;
  });

  const onSubmit = (event: FormEvent) => {
    const mail = password.startsWith('#') ? emailAdmin : emailGuest;
    const pw = password.startsWith('#') ? password.substr(1) : password;
    firebase
      ?.doLogInWithEmailAndPassword(mail, pw)
      .then(() => {
        setPassword('');
        setError('');
        history.push(ROUTES.HOME);
      })
      .catch(() => {
        setPassword('');
        setError(res.invalidPassword);
      });

    event.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <Fingerprint />
        </Grid>
        <Grid item>
          <PasswordField
            name="password"
            value={password}
            label={res.labelPassword}
            onChange={(e) => setPassword(e.target.value.toLocaleLowerCase())}
          />
        </Grid>
        <Grid item>
          {/*
                    // @ts-ignore */}
          <IconButton
            disabled={password === ''}
            variant="contained"
            type="submit"
            color="primary"
          >
            <ArrowForwardIosRoundedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item className={classes.error}>
        {error}
      </Grid>
    </form>
  );
};

export const LogInForm = withRouter(LogInFormBase);
