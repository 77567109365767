import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './components/App';

import { Firebase, FirebaseContext } from './components/Firebase';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider } from '@material-ui/core';
import { brown, teal } from '@material-ui/core/colors';
import { Typography } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: brown,
    secondary: teal,
  },
  typography: {
    htmlFontSize: 14,
    fontFamily: 'Lato',
    body1: {
      fontSize: '1em',
    },
    body2: {
      textAlign: 'justify',
    },
    h3: {
      textAlign: 'center',
      color: '#8c5221',
      fontFamily: 'Parisienne',
    },
    h4: {
      textAlign: 'center',
      color: '#8c5221',
      fontFamily: 'Parisienne',
    },
    h6: {
      textAlign: 'center',
      color: '#8c5221',
      fontSize: '1.2rem',
    },
    subtitle1: {
      textAlign: 'center',
      color: 'black',
      fontWeight: 'bold',
      fontSize: '1em',
    },
    subtitle2: {
      textAlign: 'center',
      color: 'black',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <FirebaseContext.Provider value={new Firebase()}>
      <Typography component={'div'} variant="body2">
        <App />
      </Typography>
    </FirebaseContext.Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
