import React, { FormEvent, useContext, useState } from 'react';
import { FirebaseContext } from '../Firebase';
import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { MyUser } from '../Firebase/firebase';
import { withAuthorization } from '../Session';

const useStyles = makeStyles((theme) => ({
  card: {
    background: 'whiteBright',
  },
  button: {
    margin: theme.spacing(1),
  },
  error: {
    color: '#b71c1c',
  },
  success: {
    color: '#1b5e20',
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}));

const RsvpBase = () => {
  const classes = useStyles();
  const [names, setNames] = useState('');
  const [can, setCan] = useState(true);
  const [later, setLater] = useState(false);
  const [comment, setComment] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [namesValid, setNamesValid] = useState(true);

  const firebase = useContext(FirebaseContext);

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (names.length < 4) {
      setNamesValid(false);
    } else {
      setNamesValid(true);
      setDisableSubmit(true);
      setError(false);
      setSuccess(false);
      firebase
        ?.doSubmitRsvp(names, can, later, comment)
        .then(() => {
          setSuccess(true);
        })
        .catch(() => {
          setDisableSubmit(false);
          setError(true);
        });
    }
  };

  return (
    <div>
      <Typography variant="h4">Rückmeldung:</Typography>
      <form onSubmit={onSubmit}>
        <p>
          Zur besseren Planung bitten wir um eine verbindliche Rückmeldung{' '}
          <b>bis Ende Januar</b>. Per Mail, telefonisch oder einfach direkt
          hier:
        </p>
        <RadioGroup
          name="canCome"
          defaultValue="true"
          onChange={(e) => setCan(e.target.value === 'true')}
          row
        >
          <FormControlLabel
            disabled={disableSubmit}
            value="true"
            control={<Radio color="primary" />}
            label="Wir / Ich komme(n) gerne."
          />
          <FormControlLabel
            disabled={disableSubmit}
            value="false"
            control={<Radio color="primary" />}
            label="Wir / Ich können / kann leider nicht kommen."
          />
        </RadioGroup>
        <p>
          Partner und Kinder sind herzlich willkommen, sagt uns bitte nur wer
          alles mitkommt. Bei Kindern unter 14 Jahren bitte das Alter mit
          angeben.
        </p>
        <TextField
          className={classes.textField}
          name={names}
          value={names}
          label="Ich bin / Wir sind (Namen)"
          multiline
          rows={4}
          type="text"
          variant="outlined"
          error={!namesValid}
          helperText={
            namesValid
              ? null
              : 'Vergesst nicht uns zu sagen wer sich ' +
                (can ? 'anmeldet' : 'abmeldet') +
                ' ;)'
          }
          disabled={disableSubmit}
          onChange={(e) => setNames(e.target.value)}
        />
        {can ? (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={later}
                  disabled={disableSubmit}
                  onChange={(e) => setLater(e.target.checked)}
                  color="primary"
                />
              }
              label="Wir können erst nach 15 Uhr kommen."
            />

            <p>
              Falls ihr weitere Anmerkungen habt (z.B. Kuchen mitbringen wollt,
              veganes Essen möchtet oder Anmerkungen/Vorschläge für unsere
              Webseite habt), könnt ihr das hier machen:
            </p>

            <TextField
              className={classes.textField}
              name={comment}
              value={comment}
              label="Sonstige Anmerkungen"
              multiline
              rows={4}
              type="text"
              variant="outlined"
              disabled={disableSubmit}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        ) : (
          <div>
            <TextField
              className={classes.textField}
              name={comment}
              value={comment}
              label="Sonstige Anmerkungen"
              multiline
              rows={4}
              type="text"
              variant="outlined"
              disabled={disableSubmit}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={classes.button}
          endIcon={<SendIcon />}
          disabled={disableSubmit}
        >
          {can ? 'Anmelden' : 'Abmelden'}
        </Button>
        {success ? (
          can ? (
            <p className={classes.success}>
              Danke für eure Anmeldung, wir freuen uns schon auf euch!
            </p>
          ) : (
            <p className={classes.success}>
              Schade, dass ihr nicht kommen könnt, aber vielen Dank fürs
              Bescheid geben.
            </p>
          )
        ) : null}
        {error ? (
          <p className={classes.error}>
            Leider gab es einen Fehler bei eurer Anmeldung. Probiert es am
            besten gleich oder später nochmal. Wenn es dann immer noch nicht
            funktioniert schreibt uns einfach eine E-mail an{' '}
            <a
              href={'mailTo:hochzeit@erkenberg.info'}
              target="_blank"
              rel="noopener noreferrer"
            >
              hochzeit@erkenberg.info
            </a>
          </p>
        ) : null}
        <p>
          Falls sich bei euch etwas ändert, könnt ihr euch hier einfach nochmal
          an- oder abmelden. Sollte das Formular noch ausgegraut sein, einfach
          die Seite neu laden.
        </p>
      </form>
    </div>
  );
};

const condition = (authUser: MyUser | null) => !!authUser;
export const Rsvp = withAuthorization(condition)(RsvpBase);
