import React, { ElementType } from 'react';
import { withRouter } from 'react-router-dom';
import { Firebase, withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { AuthUserContext } from './context';
import { History } from 'history';
import { MyUser } from '../Firebase/firebase';

// Takes care of redirects if user hasn't access to certain routes
export const withAuthorization = (
  condition: (authUser: MyUser | null) => boolean
) => (Component: ElementType) => {
  class WithAuthorization extends React.Component {
    private listener: () => void;
    private firebase: Firebase;
    private history: History;

    constructor(props: { firebase: Firebase; history: History }) {
      super(props);
      // eslint-disable-next-line react/prop-types
      this.firebase = props.firebase;
      // eslint-disable-next-line react/prop-types
      this.history = props.history;
    }

    componentDidMount() {
      this.listener = this.firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            this.history.push(ROUTES.LOGIN);
          }
        },
        () => this.history.push(ROUTES.LOGIN)
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(withFirebase(WithAuthorization));
};
