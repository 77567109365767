import React, { useState } from 'react';
import {
  AppBar,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { AlertDialog } from '../index';
import { resources } from '../../../resources';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ReactMarkdown from 'react-markdown';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { GenericItem } from './GenericItem';
import { v4 as uuidv4 } from 'uuid';
import { TransitionProps } from '@material-ui/core/transitions';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

const res = resources.genericListItems;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    creationDate: {
      textAlign: 'right',
      color: 'grey',
      fontSize: 'small',
    },
    editButton: {
      float: 'right',
      paddingTop: '4px',
      paddingBottom: '0',
    },
    newButton: {
      margin: 'auto',
      width: '100%',
      paddingTop: '4px',
      paddingBottom: '0',
    },
    content: {
      width: '100%',
      textAlign: 'center',
      overflowWrap: 'break-word',
      float: 'none',
    },
    dialogContent: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    previewLabel: {
      color: 'grey',
      fontSize: 'small',
    },
    appBar: {
      position: 'relative',
      justify: 'right',
    },
    title: {
      flex: 1,
      color: 'white',
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const EditableListItem = ({
  item,
  canEdit,
  addItem,
  deleteItem,
  updateItem,
  showDates = false,
}: {
  item?: GenericItem;
  canEdit: boolean;
  addItem: (item: GenericItem) => void;
  deleteItem?: (item: GenericItem) => void;
  updateItem?: (item: GenericItem) => void;
  showDates?: boolean;
}) => {
  const theme = useTheme();
  const dialogFullscreenBreakpoint = useMediaQuery(
    theme.breakpoints.down('sm')
  );

  const isNewItem = !item;
  const { id, text, title, created } = item
    ? item
    : { id: uuidv4(), text: '', title: '', created: new Date() }; //defaults for new item
  const classes = useStyles();
  const dateFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const [edit, setEdit] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [editText, setEditText] = useState(text);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onDeleteItemConfirmed = () => {
    setShowDeleteConfirmation(false);
    setEdit(false);
    if (item) deleteItem?.(item);
  };

  const handleSetTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditTitle(event.target.value);
  };
  const handleSetText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditText(event.target.value);
  };

  const onCancelEdit = () => {
    setEdit(false);
    setEditText(text);
    setEditTitle(title);
  };

  const onSaveChanges = () => {
    setEdit(false);
    if (editTitle === title && editText === text) {
      return; // nothing changed
    }
    const newItem: GenericItem = {
      id: id ?? uuidv4(),
      created: item?.created ?? new Date(),
      title: editTitle,
      text: editText,
      updated: new Date(),
      order: 0,
    };

    if (isNewItem) addItem(newItem);
    else updateItem?.(newItem);
  };

  const handleStartEdit = () => {
    if (isNewItem) {
      setEditTitle('');
      setEditText('');
    }
    setEdit(true);
  };

  const handleEndEdit = () => {
    setEdit(false);
    onCancelEdit();
  };

  const readView = (
    <div>
      {canEdit ? (
        <IconButton
          className={isNewItem ? classes.newButton : classes.editButton}
          aria-label="edit"
          color="primary"
          onClick={handleStartEdit}
        >
          {isNewItem ? (
            <AddCircleOutlineOutlinedIcon fontSize="large" />
          ) : (
            <EditOutlinedIcon />
          )}
        </IconButton>
      ) : null}
      {isNewItem ? null : (
        <div>
          <Typography variant="h6">
            <ReactMarkdown className={classes.content} source={title} />
          </Typography>
          <ReactMarkdown className={classes.content} source={text} />
          {showDates ? (
            <div className={classes.creationDate}>
              {created.toLocaleDateString('de-DE', dateFormatOptions)}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );

  const editOverlay = (
    <Dialog
      fullScreen={dialogFullscreenBreakpoint}
      open={edit}
      onClose={handleEndEdit}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton aria-label="save" onClick={onSaveChanges}>
            <SaveOutlinedIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {isNewItem ? res.labelAddItem : res.labelEditItem}
          </Typography>
          {!isNewItem && (
            <IconButton
              aria-label="delete"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
          <IconButton aria-label="cancel" onClick={handleEndEdit}>
            <ClearOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AlertDialog
        title={res.labelDeleteItem}
        content={res.textWarnDelete}
        open={showDeleteConfirmation}
        onAgree={onDeleteItemConfirmed}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
      <div className={classes.dialogContent}>
        <TextField
          className={classes.content}
          name={editTitle}
          value={editTitle}
          label={res.labelEditTitle}
          type="text"
          variant="outlined"
          multiline
          onChange={handleSetTitle}
        />
        <TextField
          className={classes.content}
          style={{ marginTop: '8px' }}
          name={editText}
          value={editText}
          label={res.labelEditText}
          type="text"
          variant="outlined"
          multiline
          onChange={handleSetText}
        />

        {showDates ? (
          <div className={classes.creationDate}>
            {created.toLocaleDateString('de-DE', dateFormatOptions)}
          </div>
        ) : null}
        <hr />
        <div className={classes.previewLabel}>{res.labelPreview}:</div>
        <Typography variant="h6">
          <ReactMarkdown className={classes.content} source={editTitle} />
        </Typography>
        <ReactMarkdown className={classes.content} source={editText} />
      </div>
    </Dialog>
  );

  return (
    <div>
      {readView}
      {editOverlay}
    </div>
  );
};
