import CardMedia from '@material-ui/core/CardMedia';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  sep: {
    margin: 'auto',
    width: '60%',
    minWidth: '200px',
    opacity: '0.6',
  },
});

export const Separator = ({ src }: { src: string }) => {
  const classes = useStyles();
  return <CardMedia className={classes.sep} src={src} component="img" />;
};
