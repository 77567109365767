import React from 'react';
import { withAuthorization } from '../Session';
import { Typography } from '@material-ui/core';
import { Countdown } from '../Countdown';
import ivyHeader from '../../images/ivyHeader.png';
import { RoundedImage, Separator } from '../BasicComponents';
import { Program } from '../Program';
import { Faq } from '../Faq';
import { Location } from '../Location';
import us2 from '../../images/us2.jpg';
import { MyUser } from '../Firebase/firebase';
import { resources } from '../../resources';
import { News } from '../News';

const res = resources.homePage;

const HomePageBase = () => {
  return (
    <>
      <Typography variant="h3">{res.title}</Typography>
      <br />
      <RoundedImage src={us2} alt={'Patricia und Stefan'} />
      <br />
      <Typography variant="h4">{res.textSubtitle}</Typography>
      <Countdown
        timeTillDate={res.countDownDate}
        timeFormat="MM DD YYYY, h:mm a"
      />
      <News />
      <div>
        <Separator src={ivyHeader} />
        <Typography variant="h4">Allgemeines:</Typography>
        <p>Wir haben am Freitag, den</p>
        <Typography variant="h6"> 22. Mai 2020</Typography>
        <p>in Eching geheiratet.</p>
        <p>Auf dieser Seite findet ihr alle Infos zu unserer Hochzeit.</p>
      </div>
      <Program />
      <Location />
      <Faq />
      <Separator src={ivyHeader} />
    </>
  );
};

const condition = (authUser: MyUser | null) => !!authUser;
export const HomePage = withAuthorization(condition)(HomePageBase);
