import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { LogIn } from '../LogIn';
import { HomePage } from '../Home';
import {
  Container,
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Rsvp, RsvpResponses } from '../RSVP';
import { Gallery } from '../Gallery';
import { NavDrawer } from '../Navigation';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: drawerWidth + 10,
      },
      minHeight: '100vh',
      maxWidth: '1300px',
    },
  })
);

const AppBase = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.content}>
        <Router>
          <NavDrawer />
          <Route path={ROUTES.GALLERY} component={Gallery} />
          <Route exact path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.LOGIN} component={LogIn} />
          <Route path={ROUTES.REGISTRATION} component={Rsvp} />
          <Route path={ROUTES.RESPONSES} component={RsvpResponses} />
        </Router>
      </Container>
    </React.Fragment>
  );
};
export const App = withAuthentication(AppBase);
