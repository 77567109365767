import React, { useContext, useEffect, useState } from 'react';
import { MyUser } from '../Firebase/firebase';
import { withAuthorization } from '../Session';
import { ImageUpload } from './ImageUpload';
import { FirebaseContext } from '../Firebase';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

interface GalleryImage {
  original: string;
  thumbnail: string;
}

const GalleryBase = () => {
  const firebase = useContext(FirebaseContext);
  const [images, setImages] = useState<Array<GalleryImage>>([]);

  useEffect(() => {
    const getUrls = async () => {
      return (await firebase?.getImageGalleryFileURLs()) ?? [];
    };
    getUrls().then((urls) =>
      setImages(
        urls.map((image) => {
          return {
            original: image.downloadUrl,
            thumbnail: image.downloadUrl,
          };
        })
      )
    );
  }, [firebase]);

  return (
    <>
      <ImageGallery
        items={images}
        lazyLoad={true}
        thumbnailPosition={'top'}
        showPlayButton={images.length > 0}
        showFullscreenButton={images.length > 0}
      />
      <ImageUpload />
    </>
  );
};

const condition = (authUser: MyUser | null) => !!authUser;
export const Gallery = withAuthorization(condition)(GalleryBase);
