/* eslint-disable no-undef */
import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase';
import * as ROLES from '../../constants/roles';
import { FirebaseCache } from './FirebaseCache';
import { GenericItem } from '../BasicComponents/lists/GenericItem';
import { FirebaseImage } from './FirebaseImage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export interface MyUser {
  role: { [ROLES.ADMIN]: string };
  email: string;
  username: string;
}

export const documentIDs = {
  news: 'news',
  faq: 'faq',
  program: 'program',
};

enum storagePath {
  IMAGES = 'images',
  USERIMAGES = 'userimages',
}

export class Firebase {
  private readonly db: firebase.firestore.Firestore;
  private readonly storage: firebase.storage.Storage;

  private auth: firebase.auth.Auth;
  private fbCache: FirebaseCache;

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.db = firebase.firestore();
    this.storage = firebase.storage();
    this.auth = firebase.auth();
    this.fbCache = new FirebaseCache(this.db);
  }

  // Auth API

  // Authlistener that merges the dbUser info with the auth user info and executes next(authUser) if a user exists and
  // otherwise fallback();
  onAuthUserListener = (
    next: (authUser: MyUser | null) => void,
    fallback: () => void
  ): (() => void) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // get merge with firestore info about the user, requires correct permissions!
        this.user(authUser.uid)
          .get()
          .then((snapshot) => {
            const dbUser = snapshot.data();
            // merge auth and db user
            next({
              role: {},
              ...dbUser,
            } as MyUser);
          });
      } else {
        fallback();
      }
    });

  doLogInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> =>
    this.auth.signInWithEmailAndPassword(email, password);

  doLogOut = async (): Promise<void> => {
    this.fbCache.clearSubscriptions();
    await this.auth.signOut();
  };

  isUserLoggedInListener = (next: () => void): (() => void) =>
    this.auth.onAuthStateChanged((user) => {
      if (user) next();
    });

  //  User API

  user = (uid: string): firebase.firestore.DocumentReference =>
    this.db.doc(`users/${uid}`);
  users = (): firebase.firestore.CollectionReference =>
    this.db.collection(`users`);

  // RSVP API

  doSubmitRsvp = (
    names: string,
    can: boolean,
    later: boolean,
    comment: string
  ): Promise<firebase.firestore.DocumentReference> =>
    this.db.collection(`responses`).add({
      names,
      can,
      later,
      comment,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });

  getRsvpResponses = (): Promise<firebase.firestore.QuerySnapshot> =>
    this.db
      .collection(`responses`)
      .orderBy('can', 'desc')
      .orderBy('timestamp', 'desc')
      .get();

  // content API
  // Things that should not be public (e.g. email) are loaded from firestore, which rejects access to it if not permitted.

  registerCallback = (
    path: string,
    id: string,
    callback: (doc: any) => void
  ): void => {
    this.fbCache.registerCallback(path, id, callback);
  };

  updateItem = (path: string, item: GenericItem): Promise<void> =>
    this.db.runTransaction((transaction) => {
      const docRef = this.db.collection('content').doc(path);
      return transaction.get(docRef).then((doc) => {
        const items = doc
          ?.data()
          ?.items.map((_item: any) => (_item.id === item.id ? item : _item));
        transaction.update(docRef, { items });
      });
    });

  deleteItem = (path: string, item: GenericItem): Promise<void> =>
    this.db.runTransaction((transaction) => {
      const docRef = this.db.collection('content').doc(path);
      return transaction.get(docRef).then((doc) => {
        const items = doc
          ?.data()
          ?.items.filter((_item: any) => _item.id !== item.id);
        if (items) transaction.update(docRef, { items });
      });
    });

  // TODO: get arrayRemove working?!
  // deleteItem = async (path: string, item: GenericItem) => {
  //   await this.db
  //     .collection('content')
  //     .doc(path)
  //     .update({ items: firebase.firestore.FieldValue.arrayRemove(item) })
  //     .catch(e => console.log(e));
  // };

  addItem = (path: string, item: GenericItem): Promise<void> =>
    this.db
      .collection('content')
      .doc(path)
      .update({ items: firebase.firestore.FieldValue.arrayUnion(item) });

  setItems = (path: string, items: GenericItem[]): Promise<void> =>
    this.db.collection('content').doc(path).set({ items });

  /**
   * Upload the given files to firebase storage.
   */
  uploadFiles = (dirName: string, files: File[]): void => {
    const storageRef = this.storage.ref();
    for (const file of files) {
      const imageRef = storageRef.child(
        `${storagePath.USERIMAGES}/${dirName}/${file.name}`
      );
      imageRef.put(file);
    }
  };

  /**
   * Get list of download URLs to all files for the Gallery.
   */
  getImageGalleryFileURLs = async (): Promise<FirebaseImage[]> => {
    const storageRef = this.storage.ref();
    const listRef = storageRef.child(storagePath.IMAGES);
    const references = await listRef.listAll();
    return await Promise.all(
      references.items.map(
        async (item): Promise<FirebaseImage> => {
          const url = await item.getDownloadURL();
          return { downloadUrl: url };
        }
      )
    );
  };
}
