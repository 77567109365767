import firebase from 'firebase';
import { DocumentData } from '@firebase/firestore-types';

/**
 * Caches existing subscriptions and data to firestore documents/collections.
 * This way updates also occur in the background and if a component registers a new callback,
 * cached content can be directly served from the cache instead of establishing a new subscription.
 */
export class FirebaseCache {
  private callbacks: Map<string, Map<string, (doc: DocumentData) => void>>;
  private cache: Map<string, DocumentData>;
  private fbSubscriptions: Map<string, () => void>;
  private db: firebase.firestore.Firestore;

  constructor(db: firebase.firestore.Firestore) {
    this.db = db;
    this.callbacks = new Map<
      string,
      Map<string, (doc: DocumentData) => void>
    >();
    this.cache = new Map<string, DocumentData>();
    this.fbSubscriptions = new Map<string, () => void>();
  }

  clearSubscriptions = (): void => {
    this.fbSubscriptions.forEach((unsub) => unsub());
    this.cache.clear();
    this.callbacks.clear();
  };

  /**
   * Register a Callback to the Firebase Cache. If there is already a callback for the given documentPath, it is replaced.
   * @param documentPath - Equals the path of a document of the 'content' collection in firestore that shall be subscribed to.
   * @param id - unique id of the subscriber. Has to be unique and constant per call (e.g. filename).
   * @param callback - called whenever the value at the documentPath changes.
   */
  registerCallback = (
    documentPath: string,
    id: string,
    callback: (doc: any) => void
  ): void => {
    if (!this.callbacks.has(documentPath)) {
      this.callbacks.set(
        documentPath,
        new Map<string, (doc: DocumentData) => void>()
      );
    }
    console.log(this.callbacks.get(documentPath)!.keys());
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.callbacks.get(documentPath)!.set(id, callback);
    console.log('registerCallback: ' + documentPath);
    this.registerSubscriber(documentPath);
    if (this.cache.has(documentPath)) callback(this.cache.get(documentPath));
  };

  registerSubscriber = (documentPath: string): void => {
    if (this.fbSubscriptions.has(documentPath)) {
      return;
    }
    console.log('register firebase subscriber: ' + documentPath);
    const unsub = this.db
      .collection('content')
      .doc(documentPath)
      .onSnapshot((doc) => {
        console.log(`got ${documentPath} update`);
        this.cache.set(documentPath, doc);
        const cbMap = this.callbacks.get(documentPath);
        if (cbMap && cbMap.values()) {
          for (const cb of cbMap.values()) {
            cb(doc);
          }
        }
      });
    this.fbSubscriptions.set(documentPath, unsub);
  };
}
