import React from 'react';
import Typography from '@material-ui/core/Typography';
import { EditableList, Separator } from '../BasicComponents';
import ivyHeader from '../../images/ivyHeader.png';
import { documentIDs } from '../Firebase/firebase';

import { resources } from '../../resources';

const res = resources.program;

export const anchor = 'program';
export const Program = () => {
  return (
    <div id={anchor}>
      <Separator src={ivyHeader} />
      <Typography variant="h4">{res.title}:</Typography>
      <br />
      <Typography variant="h6">{res.labelDate}</Typography>
      <br />
      <EditableList id="Program" firebasePath={documentIDs.program} />
    </div>
  );
};
