import React from 'react';
import TextField from '@material-ui/core/TextField';

interface PasswordProps {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

export const PasswordField = ({
  name,
  value,
  onChange,
  label = 'Passwort',
}: PasswordProps) => (
  <TextField
    name={name}
    value={value}
    label={label}
    type="password"
    onChange={onChange}
    autoComplete="current-password"
    margin="normal"
  />
);
