import { EditableListItem } from './EditableListItem';
import React, { useContext, useEffect, useState } from 'react';
import { AuthUserContext } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { FirebaseContext } from '../../Firebase';
import { Item } from '../index';

export const EditableList = ({
  showDates = false,
  sortDateDescending,
  firebasePath,
  id,
}: {
  showDates?: boolean;
  firebasePath: string;
  sortDateDescending?: boolean;
  id: string;
}) => {
  // Check whether an admin is accessing the component
  const authUser = useContext(AuthUserContext);
  const canEdit: boolean = authUser ? ROLES.ADMIN in authUser.role : false;

  const firebase = useContext(FirebaseContext);
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    const firebaseCallback = (doc: any) => {
      let tmpItems: Item[] = doc.data()?.items.map(
        (value: any): Item => {
          return {
            title: value.title,
            text: value.text,
            created: new Date(value.created.seconds * 1000),
            updated: new Date(value.updated.seconds * 1000),
            order: value.order ?? 0,
            id: value.id,
          };
        }
      );
      if (sortDateDescending !== undefined)
        tmpItems = tmpItems.sort((a, b) =>
          a.created > b.created && sortDateDescending ? -1 : 1
        );
      setItems(tmpItems);
    };
    if (firebase == null) return;
    firebase.registerCallback(firebasePath, id, firebaseCallback);
    //TODO: handle unregister to avoid undefined callbacks
  }, [firebase, firebasePath, id, sortDateDescending]);

  // Editing the items
  const addItem = async (item: Item) => {
    if (!items) {
      //TODO: check what firebase updateArray does on non existing documents
      await firebase?.setItems(firebasePath, []);
      return;
    }
    await firebase?.addItem(firebasePath, item);
  };

  const updateItem = async (item: Item) => {
    await firebase?.updateItem(firebasePath, item);
  };

  const deleteItem = async (item: Item) => {
    item && (await firebase?.deleteItem(firebasePath, item));
  };

  return (
    <div>
      {items
        ? items.map((item, id) => {
            return (
              <EditableListItem
                key={id}
                item={item}
                addItem={addItem}
                deleteItem={deleteItem}
                updateItem={updateItem}
                showDates={showDates}
                canEdit={canEdit}
              />
            );
          })
        : null}
      {canEdit ? (
        <EditableListItem addItem={addItem} canEdit={canEdit} />
      ) : null}
      <br />
      <br />
    </div>
  );
};
