import React from 'react';

import { EditableList, Separator } from '../BasicComponents';
import ivyHeader from '../../images/ivyHeader.png';
import { Typography } from '@material-ui/core';
import { documentIDs } from '../Firebase/firebase';
import { resources } from '../../resources';
import ReactMarkdown from 'react-markdown';

const res = resources.faq;

export const anchor = 'faq';
export const Faq = () => {
  return (
    <div id={anchor}>
      <Separator src={ivyHeader} />
      <Typography variant="h4">{res.title}:</Typography>
      <ReactMarkdown source={res.textIntro} />
      <br />
      <EditableList id="Faq" firebasePath={documentIDs.faq} />
    </div>
  );
};
