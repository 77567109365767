import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { resources } from '../../resources';

const res = resources.dialog;

export const AlertDialog = ({
  title,
  content,
  open,
  onAgree,
  onCancel,
}: {
  title: string;
  content: string;
  open: boolean;
  onAgree: () => void;
  onCancel: () => void;
}) => {
  return (
    <div>
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onAgree} color="primary">
            {res.labelAgree}
          </Button>
          <Button onClick={onCancel} color="primary" autoFocus>
            {res.labelCancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
