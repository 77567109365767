import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  oval: {
    display: 'block',
    margin: 'auto',
    width: '60%',
    minWidth: '200px',
    maxWidth: '500px',
    height: 'auto',
    borderRadius: '20%',
    opacity: '90%',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
});

export const RoundedImage = ({ src, alt }: { src: string; alt: string }) => {
  const classes = useStyles();
  return <img src={src} className={classes.oval} alt={alt} />;
};
