import React, { forwardRef, useContext } from 'react';
import {
  AppBar,
  createStyles,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Theme,
  Toolbar,
  useTheme,
} from '@material-ui/core';
import { Separator } from '../BasicComponents';
import ivyHeader from '../../images/ivyHeader.png';
import { LinkProps as RouterLinkProps, withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import * as ROUTES from '../../constants/routes';
import { resources } from '../../resources';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { FirebaseContext } from '../Firebase';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import PhotoLibraryOutlinedIcon from '@material-ui/icons/PhotoLibraryOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import { programAnchor } from '../Program';
import { locationAnchor } from '../Location';
import { faqAnchor } from '../Faq';
import { newsAnchor } from '../News';

const res = resources.navigation;

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      marginLeft: drawerWidth,
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeNavItem: {
      background: theme.palette.primary.light,
    },
    navItemChilds: {
      paddingLeft: theme.spacing(9),
      color: theme.palette.primary.main,
    },
  })
);

const NavDrawerBase = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { container, location } = props;

  const firebase = useContext(FirebaseContext);
  const authUser = useContext(AuthUserContext);
  const theme = useTheme();

  if (!authUser || !Object.prototype.hasOwnProperty.call(authUser, 'role')) {
    return null;
  }

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      !!event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const navItemsGuests = [
    {
      label: res.labelInfo,
      to: ROUTES.HOME,
      icon: <HomeOutlinedIcon />,
      children: [
        {
          label: res.labelNews,
          anchor: newsAnchor,
        },
        {
          label: res.labelProgram,
          anchor: programAnchor,
        },
        {
          label: res.labelLocation,
          anchor: locationAnchor,
        },
        {
          label: res.labelFaq,
          anchor: faqAnchor,
        },
      ],
    },
    {
      label: res.labelGallery,
      to: ROUTES.GALLERY,
      icon: <PhotoLibraryOutlinedIcon />,
    },
    // {
    //   label: res.labelRegistration,
    //   to: ROUTES.REGISTRATION,
    //   icon: <AssignmentOutlinedIcon />,
    // },
  ];
  const navItemsAdmin = [
    {
      label: res.labelResponses,
      to: ROUTES.RESPONSES,
      icon: <LibraryBooksOutlinedIcon />,
    },
  ];

  const isAdmin = authUser.role[ROLES.ADMIN];
  const drawer = (
    <div>
      <Separator src={ivyHeader} />
      <List>
        {navItemsGuests.map((navItem) => (
          <ListItemLink
            to={navItem.to}
            primary={navItem.label}
            icon={navItem.icon}
            key={navItem.label}
            active={location.pathname === navItem.to}
          >
            {navItem.children}
          </ListItemLink>
        ))}
        {isAdmin &&
          navItemsAdmin.map((navItem) => (
            <ListItemLink
              to={navItem.to}
              primary={navItem.label}
              icon={navItem.icon}
              key={navItem.label}
              active={location.pathname === navItem.to}
            />
          ))}
        <ListItem button key={res.labelLogOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText
            primary={res.labelLogOut}
            onClick={firebase?.doLogOut}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setOpen(!open)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            onClick={toggleDrawer(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <SwipeableDrawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
      </nav>
      <div className={classes.toolbar} />
      <br />
    </div>
  );
};

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  active: boolean;
  children?: { label: string; anchor: string }[];
}

function ListItemLink(props: ListItemLinkProps) {
  const classes = useStyles();
  const { icon, primary, to, active, children } = props;

  const getLink = (to: string) =>
    // eslint-disable-next-line react/display-name
    forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
      <Link
        to={to}
        ref={ref}
        {...itemProps}
        scroll={(el) =>
          window.scrollTo({
            top: el.getBoundingClientRect().top + window.pageYOffset - 64,
            behavior: 'smooth',
          })
        }
      />
    ));

  return (
    <div>
      <ListItem
        button
        component={getLink(to)}
        className={active ? classes.activeNavItem : undefined}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
      {children && active ? (
        <List component="div" disablePadding>
          {Object.values(children).map((child) => (
            <ListItem
              key={child.label}
              className={classes.navItemChilds}
              button
              component={getLink(`#${child.anchor}`)}
            >
              <ListItemText primary={child.label} />
            </ListItem>
          ))}
        </List>
      ) : null}
    </div>
  );
}

export const NavDrawer = withRouter(NavDrawerBase);
